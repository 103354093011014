<template>
  <div
    class="modal fade"
    id="addEventoModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl bg-dark">
      <div class="modal-content bg-dark">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Nuevo Evento</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="onAddEvento">
            <div class="row">
              <div class="col-4">
                <label class="text-light">Deporte</label>
                <select
                  name=""
                  id=""
                  class="form-select"
                  v-model="deporte_id"
                  @change="selectDeporte"
                >
                  <option :value="d.id" v-for="d in lstDeporte" :key="d.id">
                    {{ d.nombre }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-3">
                <label class="text-light">Evento</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="objAddEvento.nombre"
                />
              </div>
              <div class="col-3">
                <label class="text-light">Fecha inicio</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="objAddEvento.fecha_ini"
                />
              </div>
              <div class="col-3">
                <label class="text-light">Fecha fin</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="objAddEvento.fecha_fin"
                />
              </div>
              <div class="col-3">
                <label for="" class="text-light">Competidores</label>
                <div>
                  <Multiselect
                    style="color: black"
                    v-model="objAddEvento.competidores"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="true"
                    :options="arrCompetidores"
                  />
                </div>
              </div>
            </div>

            <div class="row justify-content-center mt-5">
              <div class="col-md-2">
                <button
                  type="submit"
                  class="myButtonAceptar"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { onMounted, reactive, ref } from "vue-demi";
import usePronostico from "../composable/usePronostico";
import { useStore } from "vuex";

export default {
  props: ["pronostico_id"],
  components: {
    Multiselect,
  },
  setup(props) {
    const objAddEvento = reactive({
      id: null,
      nombre: null,
      resultado: null,
      fecha_ini: null,
      fecha_fin: null,
      competidores: null,
      pronostico_id: null,
    });
    const {
      getDeporte,
      lstDeporte,
      getCompetidores,
      lstCompetidores,
      addEvento,
      getEvento,
    } = usePronostico();

    const arrCompetidores = ref([]);
    const competidores_id = ref([]);
    const deporte_id = ref(null);
    const store = useStore();

    onMounted(async () => {
      store.commit("showLoader");
      await getDeporte();
      objAddEvento.pronostico_id = props.pronostico_id;
      store.commit("hideLoader");
    });

    const selectDeporte = async () => {
      store.commit("showLoader");
      arrCompetidores.value = [];
      await getCompetidores({ deporte_id: deporte_id.value, nombre: ""});

      await lstCompetidores.value.forEach((element) => {
        let obj = {
          value: element.id,
          label: element.nombre,
        };
        arrCompetidores.value.push(obj);
      });

      store.commit("hideLoader");
    };

    const onAddEvento = async () => {
      store.commit("showLoader");      
      await addEvento(objAddEvento);
      await getEvento({ pronostico_id: props.pronostico_id });

      objAddEvento.id = null;
      objAddEvento.nombre = null;
      objAddEvento.resultado = null;
      objAddEvento.fecha_ini = null;
      objAddEvento.fecha_fin = null;
      objAddEvento.competidores = null;

      store.commit("hideLoader");
    };
    return {
      onAddEvento,
      objAddEvento,
      arrCompetidores,
      deporte_id,
      lstDeporte,
      selectDeporte,
      competidores_id,
    };
  },
};
</script>

<style>
.addEventos {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
}

.multiselect.invalid .multiselect__tags,
.multiselect.invalid .multiselect__tags span,
.multiselect.invalid .multiselect__tags input {
  background: red;
}
</style>