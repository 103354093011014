<template>
  <h3 class="text-light mt-3">Editando Pronóstico</h3>
  <form
    @submit.prevent="submitPronostico"
    class="pronosticoForm"
    v-if="editPronosticoOBJ.id"
  >
    <div class="text-end text-light">
      <button type="button" class="btnEliminarTable" @click="irPronostico()">
        X
      </button>
    </div>

    <div class="row">
      <div class="col-3">
        <label class="text-light">Nombre</label>
        <input
          type="text"
          class="form-control"
          v-model="editPronosticoOBJ.nombre"
        />
      </div>
      <div class="col-4">
        <label class="text-light">Imagen</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="editPronosticoOBJ.imagen1"
          />
          <button class="btn btn-danger" type="button" @click="limpiarInput()">
            X
          </button>
          <button class="btn btn-success" type="button" @click="pegarImagen">
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
      </div>
    </div>
    <button
      class="btn btn-info mr-2"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#addEventoModal"
    >
      Agregar Evento
    </button>

    <AddEventoModal :pronostico_id="editPronosticoOBJ.id" />

    <div class="card table-responsive-sm mt-5">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Evento</th>
            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody v-for="d of lstEvento" v-bind:key="d.id">
          <tr style="cursor: pointer">
            <td @click="irTo(d)">{{ d.nombre }}</td>
            <td>
              <button
                type="button"
                class="btnEliminarTable"
                @click="eliminarEvento(d)"
              >
                <i style="color: #fff" class="far fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-md-2">
        <button type="submit" class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { onMounted, reactive } from "vue-demi";
import { useRoute, useRouter } from "vue-router";
import usePronostico from "../composable/usePronostico";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import AddEventoModal from "../components/AddEventoModal.vue";

import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";
import useUtil from "../../../use/useUtil";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const { onPegarTexto } = useUtil();

    const {
      getEvento,
      lstPronostico,
      lstEvento,
      editPronostico,
      deleteEvento,
    } = usePronostico();
    const editPronosticoOBJ = reactive({
      id: null,
      nombre: "",
      deleted: false,
    });
    onMounted(async () => {
      store.commit("showLoader");
      let id = route.params["id"];

      await getEvento({ pronostico_id: id });
      editPronosticoOBJ.id = lstPronostico.value[0].pronostico.id;
      editPronosticoOBJ.nombre = lstPronostico.value[0].pronostico.nombre;
      editPronosticoOBJ.imagen1 = lstPronostico.value[0].pronostico.imagen1;
      store.commit("hideLoader");
    });
    const submitPronostico = async () => {
      store.commit("showLoader");
      let resp = "";
      resp = await editPronostico(editPronosticoOBJ);
      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }
      router.push("/pronosticos");
      store.commit("hideLoader");
    };
    const irTo = async (d) => {
      router.push("/eventos-editar/" + d.id);
    };
    const irPronostico = async () => {
      router.push("/pronosticos");
    };
    const limpiarInput = () => {
      editPronosticoOBJ.imagen1 = null;
    };
    const eliminarEvento = async (e) => {
      store.commit("showLoader");
      await deleteEvento(e);

      let id = route.params["id"];
      await getEvento({ pronostico_id: id });
      editPronosticoOBJ.id = lstPronostico.value[0].pronostico.id;
      editPronosticoOBJ.nombre = lstPronostico.value[0].pronostico.nombre;
      editPronosticoOBJ.imagen1 = lstPronostico.value[0].pronostico.imagen1;
      store.commit("hideLoader");
    };

    const pegarImagen = async () => {
      editPronosticoOBJ.imagen1 = "";
      editPronosticoOBJ.imagen1 = await onPegarTexto();
    };
    return {
      editPronosticoOBJ,
      lstEvento,
      submitPronostico,
      irTo,
      irPronostico,
      limpiarInput,
      eliminarEvento,
      pegarImagen,
    };
  },
  components: { AddEventoModal, GaleriaBtn },
};
</script>

<style scoped>
.pronosticoForm {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 100%;
  margin: auto;
}
</style>